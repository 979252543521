.product-layout {
  padding: 0;
  margin: 5px 0 0;
  border: 1px solid #c9c8c8;
  @media (max-width: 576px) {
    max-width: 344px;
    margin: 5px auto 0 auto;
    float: inherit;
  }

  &:hover {
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.75);
    transition: .15s;
  }

  .product-thumb {

    .image {
      a {
        display: block;
        width: 189px;
        height: 189px;
        margin: 6px auto 8px;

        img {
          display: block;
          width: 189px;
          height: 189px;
          margin: auto;
        }
      }
    }

    .caption {
      a {
        font-size: 15px;
        color: #696868;
        font-family: Arial, sans-serif;
        font-weight: 400;
        margin: 0;
        height: 45px;
        text-decoration: none;
        text-align: center;
        display: block;

        &:hover {
          text-decoration: underline;
        }
      }

      p.description {
        display: none;
      }
    }

    .button-group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price {
        margin: 0;
        font-size: 24px;
        font-family: Arial, sans-serif;
        font-weight: 400;
        color: #d7100e;
        width: 56%;
        text-align: center;
      }

      button {
        width: 99px;
        height: 35px;
        float: left;
        box-sizing: border-box;
        background: linear-gradient(to bottom, rgba(253, 152, 51, 1) 0%, rgba(236, 60, 2, 1) 100%);
        color: #fff;
        outline: none;
        border: 1px solid #da6702;
        font-size: 17px;
        font-weight: 400;
        font-family: Arial, sans-serif;

        span {
          display: block !important;
        }
      }
    }

    .stock {
      margin: 6px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #18bc37;
      font-size: 13px;
      font-family: Arial, sans-serif;
      min-height: 32px;
    }
  }
}

.breadcrumb {
  background: transparent;
  padding: 0 30px;

  li:before {
    content: '>>' !important;
    color: #656565 !important;
    position: relative;
    top: -1px;
  }

  li:first-child:before {
    content: '' !important;
  }

  li:last-child a {
    color: #ac0607;
  }

  a {
    color: #656565;
    font-size: 11px;
    font-family: Arial, sans-serif;

    &:hover {
      text-decoration: underline;
    }
  }
}

.breadcrumb.productCategory {
  padding: 0;
}

.singleProduct {

  &__content {
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.75);
    border: 1px solid rgb(201, 200, 200);
    margin: 0;
    display: flex;
    @media (max-width: 576px) {
      flex-direction: column;
    }

    &-left {
      min-height: 376px;
      text-align: center;
      position: relative;
      padding: 0;
      border-right: 1px solid rgb(201, 200, 200);

      .wrap {
        padding: 15px;
        border-bottom: 1px solid rgb(201, 200, 200);

        .img {
          margin-bottom: 40px;
        }
      }

      .tags {
        padding: 15px;
      }

      .thumbnail {
        padding: 0;
        margin: 0;
        border: none;
      }

      .slider-for {
        margin-bottom: 20px;

        img {
          display: block;
          width: 346px;
          height: 346px;
          margin: auto;
          object-fit: contain;
        }
      }

      .slider-nav {
        display: flex;
        align-items: center;

        ul {
          width: 100%;

          .slick-track {
            width: 100% !important;
          }

          .slick-slide {
            width: auto !important;
          }

          li {
            img {
              display: block;
              width: 71px;
              height: 71px;
              margin: auto;
              cursor: pointer;
            }
          }
        }

        .slick-arrow {
          cursor: pointer;
        }

        .next {
          img {
            transform: scale(-1, 1)
          }
        }
      }
    }

    &-right {
      max-width: 396px;
      padding: 32px;

      .title {
        margin: 0;
        font-weight: 400;
        font-size: 22px;
        color: #696868;
        font-family: Arial, sans-serif;
      }

      .article {
        margin: 13px 0 23px;
        font-size: 13px;
        color: #ac0607;
        font-family: Arial, sans-serif;
        font-weight: 400;

        span {
          color: #272727;
        }
      }

      .faq {
        h3 {
          font-size: 24px;
          color: #333333;
        }

        .text {
          font-size: 14px;
          color: #333;
        }

        a {
          color: #337ab7;
          text-decoration: none;
          font-size: 14px;

          &:hover {
            color: #23527c;
            text-decoration: underline
          }
        }
      }

      .inStock {
        text-align: center;
        color: #18bc37;
        margin: 17px 0 0;

        img {
          margin: 6px auto;
        }
      }

      .price {
        display: flex;
        align-items: center;
        max-width: 240px;
        justify-content: space-between;
        margin: 0 auto;

        ul {
          li {
            h2 {
              margin: 0;
              padding-top: 5px;
              font-size: 36px;
              font-family: Arial, sans-serif;
              font-weight: 400;
              color: #d7100e;
            }
          }
        }

        #product {
          button {
            width: 99px;
            height: 35px;
            display: block;
            float: right;
            margin: 8px 0;
            box-sizing: border-box;
            background: linear-gradient(to bottom, rgba(253, 152, 51, 1) 0%, rgba(236, 60, 2, 1) 100%);
            color: #fff;
            outline: none;
            border: 1px solid #da6702;
            font-size: 17px;
            font-weight: 400;
            font-family: Arial, sans-serif;
            border-radius: 0;
          }
        }
      }

      .button {
        text-align: center;
        margin: 15px 0 0;

        button {
          &:first-child {
            margin-bottom: 10px;
          }

          font-size: 15px;
          font-family: Arial, sans-serif;
          color: #fff;
          background: linear-gradient(to bottom, rgba(239, 52, 55, 1) 0%, rgba(172, 6, 6, 1) 100%);
          border: 1px solid #840607;
          text-decoration: none;
          padding: 7px 11px 8px;
          outline: none;
        }
      }
    }
  }
}

.productManufacturer__tags {
  margin-top: 52px;
}

.brands_list_title {
  margin: 5px;
  font-weight: bold;
}

ul.tags {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  max-height: 200px;
  overflow-y: scroll;

  li {
    list-style-type: none;
    border-bottom: 1px solid transparent;
    margin: 4px;

    &:hover {
      transition: .15s;
      border-bottom: 1px solid;
    }

    a {
      font-size: 12px;
      color: #696868;
    }
  }
}

.productList {
  margin-bottom: 50px;
  &.row {
    display: flex;
    flex-wrap: wrap;
  }
}

.buyClick {
  border-radius: 2px;
  max-width: 350px !important;

  &__top {
    overflow: hidden;
    background: #B15050;
    color: #fff;
    text-shadow: 0 -1px 0 #1B457D;
    font-size: 13px;
    position: relative;
    padding: 12px;

    &-title {
      max-width: 280px;
    }

    &-close {
      position: absolute;
      top: 4px;
      right: 8px;
      cursor: pointer;
    }
  }

  &__title {
    padding: 12px;
    color: #333;
  }

  &__form {
    .field {
      padding: 0 12px 12px;

      label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 5px;
        font-weight: bold;
        color: #333;
      }

      input {
        outline: none;
        box-shadow: none !important;
      }

      input.error {
        border: 1px solid red;
      }
    }

    .button {
      border-top: 1px solid #e6e6e6;
      background: #f2f2f2;
      padding: 10px 0 0 0;
      position: relative;
      text-align: center;

      .result {
        border-top: 1px solid #070;
        color: #070;
        background: #c8ed9c;
        padding: 8px 10px;
      }

      button {
        padding: 10px;
        background: #b15555;
        color: white;
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
  }
}


.sendQuestion {
  max-width: 332px !important;

  &__success {
    display: none;
    margin: 0;
    padding: 70px 0;
    font: normal 18px Calibri, arial, sans-serif;
    line-height: 22px;
    text-align: center;
    color: rgb(205, 21, 44);
  }

  &__wrap {
    padding: 15px;

    &-title {
      font-size: 26px;
      font-weight: 400;
      color: #cd152c;
      margin: 0 0 10px;
      font-family: Arial, sans-serif;
      text-align: center;
    }

    &-product {
      color: #444;
      font-size: 14px;
      margin-bottom: 10px;

    }

    &-form {
      .field {
        padding: 0;
        max-width: 236px;
        margin: 5px auto;

        input {
          box-shadow: none;
        }

        input.error {
          border: 1px solid red;
        }

        textarea {
          box-shadow: none;
          width: 100%;
          min-height: 100px;
        }
      }

      button {
        color: #fff;
        outline: none;
        display: block;
        margin: 15px auto 10px auto;
        padding: 6px 50px;
        border: 1px solid #da6702;
        font-size: 17px;
        font-weight: 400;
        font-family: Arial, sans-serif;
        background: linear-gradient(to bottom, rgba(253, 152, 51, 1) 0%, rgba(236, 60, 2, 1) 100%);
      }
    }
  }
}

.relatedProducts {
  .h2 {
    border-bottom: none;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }
}

.topProducts {
  padding: 30px;

  ul {
    padding: 0;
    margin: 0;
    columns: 4;
    @media (max-width: 576px) {
      columns: 2;
    }

    li {
      list-style-type: none;

      a {
        color: black;
        font-size: 14px;

        &:hover {
          text-decoration: underline;
          opacity: .8;
        }
      }
    }
  }
}


.product {
  &_tagsList {
    margin-bottom: 30px;
  }

  &_tagName {
    display: inline-block;
    font-weight: bold;
    margin-right: 10px;
  }

  &_tags {
    display: inline-block;
  }
}
