// remove mmobile chrome tap highlight
* {
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

img {
  max-width: 100%;
  height: auto;
}

input, textarea, button {
  font-family: inherit;
}
