ul.pagination {
  li {
    a {
      color: #696868;
    }
  }

  li.active {
    span {
      background: linear-gradient(to bottom, #fd9833 0%, #ec3c02 100%);
      border-color: #f15810;
    }
  }
}

.productPagination {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .text-left {
    width: 66%;
  }

  .text-right {
    width: 34%;
    margin: 20px 0;
  }
}

.alert-success {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 0;

  .alertSuccess-wrap {
    background: white;
    padding: 6px;
    border-radius: 3px;
    max-width: 300px;
    border: 1px solid #f4691a;
    position: relative;
    font-size: 12px;
  }

  a {
    color: #696868;
    text-decoration: underline;

    &:hover {
      color: #696868;
    }
  }

  button {
    position: absolute !important;
    top: 4px !important;
    right: 10px !important;
  }
}

.blog {
  .blog_grid_holder {
    width: 100%;
  }
}

.block_param {
  .title_filter {
    font-weight: 500;
    font-size: 14px;
  }

  .skrit {
    display: block !important;

    .row_blok {
      a {
        font-size: 14px;
        font-family: Arial, sans-serif;
        color: #696868;
      }
    }
  }
}

.productSearch {
  margin-bottom: 40px;

  .row {
    margin-bottom: 10px;
  }

  input, select {
    border: 1px solid #f26115;
    outline: none;
    cursor: pointer;
  }

  input.btn {
    width: 99px;
    height: 35px;
    float: left;
    box-sizing: border-box;
    background: linear-gradient(to bottom, #fd9833 0%, #ec3c02 100%);
    color: #fff;
    outline: none;
    border: 1px solid #da6702;
    font-size: 17px;
    font-weight: 400;
    font-family: Arial, sans-serif;
  }

  .checkbox-inline input {
    margin-top: 0;
  }
}

#error-not-found {
  p {
    font-size: 14px;
    font-family: Arial, sans-serif;
    color: #696868;
  }

  .pull-right {
    margin-top: 20px;
    float: left !important;

    a {
      float: left;
      box-sizing: border-box;
      background: linear-gradient(to bottom, #fd9833 0%, #ec3c02 100%);
      color: #fff;
      outline: none;
      border: 1px solid #da6702;
      font-size: 17px;
      font-weight: 400;
      font-family: Arial, sans-serif;
      display: flex;
      align-items: center;
      padding: 2px 14px;
    }
  }
}

.popularProduct {
  &__wrap {
    display: flex;
    align-items: center;
    overflow: hidden;

    .owl-carousel {
      width: 720px;
      margin: 10px 0 0;
      float: left;

      .owlItem {
        margin: 0 6px;
      }

      .owl-item {
        a {
          font-size: 14px;
          color: #696868;
          font-family: Arial, sans-serif;
          text-align: center;
          display: block;

          span {
            margin: 9px;
            min-height: 32px;
            display: block;
          }
        }
      }
    }

    .control {
      width: 40px;
      float: left;

      button {
        display: block;
        border: none;
        outline: none;
        background: url(/catalog/view/app/assets/img/arrow.png) no-repeat;
        width: 15px;
        height: 47px;
        margin: auto;
      }
    }

    .next_button {
      transform: scale(-1, 1);
    }
  }
}

.article {
  font: 400 15px Arial, arial, sans-serif;
  color: #656565;
  padding: 10px 5px;
  font-weight: 700;
}

.contactContent {
  display: flex;

  .info {
    &__store, &__address, &__tel {
      font-size: 16px;
    }

    &__tel {
      margin-bottom: 10px;

      a {
        color: #656565;
      }
    }

    &__email {
      margin-bottom: 18px;

      a {
        font-size: small;
        color: #656565;
      }
    }

    &__open {
      font-size: 16px;
    }
  }
}

.contactLink {
  a {
    color: #337ab7;
    text-decoration: none;
    font-size: 16px;
    display: block;

    &:hover {
      color: #23527c;
      text-decoration: underline
    }

    &:first-child {
      margin-bottom: 18px;
    }
  }
}

.pageBlog {
  .blog_item {
    display: flex;
    margin-bottom: 20px;

    .img {
      margin-right: 6px;

      img {
        object-fit: cover;
      }
    }

    .description {
      h2 {
        margin: 0;

        a {
          color: #696868;
          font-size: 14px;

          &:hover {
            color: #cd152c;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

#form-testimonial {
  #button-testimonial {
    background: linear-gradient(to bottom, #fd9833 0%, #ec3c02 100%);
    border-color: #f15810;
    font-size: 17px;
    font-weight: 400;
    padding: 2px 14px;
  }
}

#column-left {
  @media (max-width: 576px) {
    display: none !important;
  }
}

.seoText {
  padding: 0 15px;
  margin-bottom: 20px;
}

.so-onepagecheckout .checkout-cart tbody tr td a.product-name {
  white-space: break-spaces;
}


$colorLogo: rgb(218, 37, 28);

#simplecheckout_button_cart {
  span {
    background: $colorLogo;
    padding: 7px;
  }
}

.simplecheckout-cart {
  .quantity {
    .btn.btn-danger, .btn.btn-primary {
      &:hover {
        background: transparent !important;
        box-shadow: none !important;
      }
    }

    .fa-minus, .fa-plus {
      color: black;
      margin: 0 4px;
    }

    .fa-times-circle {
      color: $colorLogo;
      margin-left: 10px;
    }
  }
}

#simplecheckout_button_confirm {
  span {
    background: $colorLogo;
    padding: 15px;
    font-size: 15px;
  }
}

#button-confirm {
  background: $colorLogo;
  padding: 15px;
  font-size: 15px;
}

.checkoutsimplecheckout {
  h1 {
    margin-bottom: 30px;
  }
}
