.header__nav {
  position: relative;
}
#form-language {
  &.active {
    .dropdown-toggle {
      box-shadow: none;
    }

    .list {
      display: block !important;
    }
  }
}
.langSwitcher {
  position: absolute;
  right: 5px;

  .dropdownLang {
    border: none;
    background: none;
    padding: 5px;
    background: rgb(77, 130, 193);
    color: white;
  }

  .list {
    left: 4px;
    position: relative;
  }

  .language-select {
    padding: 0;
    border: none;
    margin-top: 5px;
    background: none;
  }

  .btn-link {
    color: black;
  }

  .btn-group {
    .list {
      display: none;
    }
  }

}

.inactive {
  opacity: 0.65;
  filter: blur(1px);
}


#filter_vier {
  width: 222px;
  background: rgb(247, 246, 246);
  box-shadow: 0px 2px 9px 0px rgba(79, 78, 78, 0.75);
  padding: 7px 10px 7px 20px;
  margin-top: 30px;
  margin-bottom: 30px;

  .title_p_f, .text_param {
    color: rgb(105, 104, 104);
  }
}


.miniTitle {
  font-size: 14px;
  margin-left: 20px;
  color: rgb(172, 6, 7);
  margin-top: 10px;
}
