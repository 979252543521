.blog_item {
  padding: 0;

  img {
    display: block;
    width: 55px;
    height: 55px;
    transition: .2s;
    float: left;
    margin: 0 6px 0 0;
  }

  .summary {
    .blog_title {
      a {
        font-size: 14px;
        color: #696868;
        font-family: Arial, sans-serif;
        font-weight: 400;
        display: block;
        padding-top: 3px;

        &:hover {
          color: #2389bf;
          text-decoration: underline;
        }
      }
    }

    p {
      font-size: 13px;
      font-family: Arial, sans-serif;
      margin: 5px 0;
      transition: .2s;
      line-height: 1.2;
    }
  }
}

.blog_grid_holder {
  display: block;
  text-decoration: none;
  color: #272727;
  text-align: justify;
  width: 222px;
  margin: 9px auto 0;
}

.bannerItem {
  margin: 20px auto;
  display: block;
  width: 192px;
  height: 492px;

  img {
    display: block;
    margin: auto;
    width: 192px;
    height: 492px;
  }
}

.blogLatest__more {
  text-align: right;
  padding-right: 5px;
  width: 222px;
  margin: 9px auto 0;
}

.moreTitle a {
  font-size: 12px;
  font-family: Arial, sans-serif;
  color: #ac0607;

  &:hover {
    color: #e53b3c;
    text-decoration: underline;
  }
}
