.footer {
  padding: 10px 0;
  background: #f7f6f6;
  font-size: 14px;
  color: #333;
  @media (max-width: 576px) {
    text-align: center;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
    }
  }

  &__wrap {
    .container {
      padding: 20px;

      .row {
        margin: 0;
      }
    }

    &-contact {
      .copyright, .title, .phone, .address {
        margin-bottom: 10px;
      }

      .address, .grafic {
        font-weight: 700;
      }

      .phone {
        ul {
          li {
            a {
              font-weight: 700;
              color: #333;
              margin-left: 2px;

              &:hover {
                color: #333;
              }
            }

            &:first-child {
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    &-nav {
      padding-top: 7px;
      padding-bottom: 7px;

      a {
        font-size: 14px;
        font-family: Arial, sans-serif;
        color: #696868;

        &:hover {
          text-decoration: underline;
          color: rgb(255, 0, 0);
        }
      }
    }

    &-info {
      padding-top: 11px;
      padding-bottom: 11px;

      .social__title, .payment__title {
        font-family: Arial, sans-serif;
        color: #656565;
        font-size: 14px;
        margin: 0;
      }

      ul {
        padding: 0;
        margin: 14px 0 19px;
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 576px) {
          justify-content: center;
        }

        li {
          margin-right: 5px;

          a {
            margin: 0;
            opacity: 1;
            transition: .15s;

            &:hover {
              opacity: .7;
            }

            img {
              width: 32px;
              height: 32px;
              display: block;
              backface-visibility: hidden;
            }
          }
        }
      }
    }
  }
}

.chat-circle {
    position: fixed;
    z-index: 999;
    bottom: 75px;
    left: 20px;
    &.active {
        ul {
            visibility: visible;
            opacity: 1;
        }
        i.fa-times {
            display: block;
        }
        i.fa-comments {
            display: none;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;

        li {
            margin-bottom: 10px;
        }
    }

    span, li {
        background: #000;
        opacity: .5;
        color: #fff;
        outline: none;
        border: 1px solid #000;
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;

        i {
            font-size: 30px;
        }
        i.fa-times {
            display: none;
        }

        img {
            width: 40px;
            height: 40px;
        }
    }
}