.h1,
.h2 {
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.h1 {
  font-size: 54px;
  line-height: 1.26;
  @include tablet {
    font-size: 30px;
    line-height: 1.35;
  }
  @include media-max(374) {
    font-size: 26px;
    line-height: 1.56;
  }
}

.h2 {
  font-size: 38px;
  line-height: 1.39;
  @include tablet {
    font-size: 18px;
    line-height: 1.56;
  }
}

.h3 {
  margin: 0;
  font-size: 24px;
  line-height: 1.68;

  @include tablet {
    font-size: 18px;
  }
}

.heading-modal {
  margin: 0;
  font-size: 32px;
  line-height: 1.39;

  @include tablet {
    font-size: 18px;
    line-height: 1.56;
  }
}

.heading-article {
  font-size: 31px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  color: $c-red;

  @include tablet {
    font-size: 20px;
    line-height: 1.5 ;
  }
}

.c-white {
  color: $c-white;
}

.c-black {
  color: $c-black;
}

.c-red {
  color: $c-red;
}

.c-primary {
  color: $c-primary;
}
