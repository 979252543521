.header {
  &__redline {
    height: 4px;
    width: 100%;
    background: #fc0000;
  }

  &__nav {
    &-button {
      display: none;
      width: 24px;
      height: 24px;
      background: #9a0308;
      @media (max-width: 576px) {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;
        justify-content: center;
        height: 46px;
      }

      span {
        background: #fff;
        display: block;
        width: 24px;
        height: 3px;
        border-radius: 3px;
        position: relative;

        &:before, &:after {
          content: '';
          display: block;
          background: #fff;
          width: 24px;
          height: 3px;
          border-radius: 3px;
          position: absolute;
        }

        &:before {
          top: -8px;
        }

        &:after {
          top: 8px;
        }

      }
    }

    ul {
      margin: 0 10px 0 217px;
      padding: 0;
      display: block;
      float: left;
      max-width: 650px;
      list-style: none;
      text-align: center;
      @media (max-width: 768px) {
        max-width: 550px;
        margin: 0 auto;
      }
      @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 100%;
      }

      li {
        margin: 0 7px;
        padding: 0;
        display: inline-block;
        position: relative;
        padding-top: 9px;

        &:before {
          display: block;
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 4.5px 0 4.5px;
          border-color: #fc0000 transparent transparent transparent;
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -5px;
          @media (max-width: 576px) {
            display: none;
          }
        }

        a {
          display: block;
          font-family: 'Calibri', Arial, sans-serif;
          font-size: 14px;
          padding: 0 0 1px;
          text-transform: uppercase;
          color: #424141;
          text-decoration: none;
          border-bottom: 1px solid transparent;
          transition: .15s;
          @media (max-width: 576px) {
            color: white;
            text-align: center;
            font-size: 20px;
            text-decoration: underline;
            text-transform: none;
          }
        }
      }
    }

    nav {
      @media (max-width: 576px) {
        display: none;
        background: #9a0308;
      }

      .callback {
        background: url("/catalog/view/app/assets/img/callback.png") no-repeat;
        width: 180px;
        height: 37px;
        display: block;
        position: relative;
        top: -4px;
        margin-bottom: -4px;
        outline: none !important;
        opacity: 1;
        transition: .2s;
        border: none;
        cursor: pointer;
        float: right;
        @media (max-width: 768px) {
          top: -56px;
        }
        @media (max-width: 576px) {
          display: none;
        }
      }
    }
  }

  &__content {
    display: flex;
    padding-bottom: 30px;
    @media (max-width: 768px) {
      justify-content: space-between;
    }

    &-logo {
      @media (max-width: 768px) {
        display: none;
      }

      a {
        display: block;
        float: left;
        margin: 0 31px;
        position: relative;
        top: -23px;
        margin-bottom: -23px;

        img {
          display: block;
          width: 146px;
          height: 166px;
          object-fit: contain;
        }
      }
    }

    &-title {
      display: block;
      float: left;
      width: 567px;
      text-align: right;
      @media (max-width: 768px) {
        width: 50%;
      }
      @media (max-width: 576px) {
        display: none;
      }

      .mainTitle {
        img {
          width: 100%;
          margin-top: 8px;
        }
      }

      .line {
        overflow-wrap: normal;
        white-space: nowrap;
        overflow: hidden;
        margin: 0 0 0 10px;
        height: 1.5em;
        line-height: 1.5em;
        position: relative;
        display: block;
      }
    }

    &-cart {
      @media (max-width: 576px) {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
      }

      .content {
        display: flex;
        align-items: center;
        padding: 19px 0 0 48px;
        @media (max-width: 576px) {
          padding: 0;
          flex-direction: column;
        }

        &__button {
          display: none;
          background: #9a0308;
          cursor: pointer;
          @media(max-width: 576px) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 46px;
            width: 100%;
          }

          i {
            color: #fff;
            font-size: 24px;
            font-weight: bold;
          }
        }

        img {
          width: 68px;
          height: 75px;
          float: left;
          display: block;
          margin-right: 8px;
          @media(max-width: 576px) {
            display: none;
          }
        }
      }

      .data {
        display: flex;
        flex-direction: column;
        @media(max-width: 576px) {
          display: none;
          width: 100%;
        }

        &__wrap {
          display: flex;
          flex-direction: column;
          @media (max-width: 576px) {
            padding: 10px;
            background: #9a0308;
            text-align: center;
          }
        }

        .title {
          margin: 0;
          font-family: 'Calibri', Arial, sans-serif;
          font-weight: 400;
          font-size: 18px;
          text-transform: uppercase;
          color: #272727;
          @media (max-width: 576px) {
            color: white;
          }
        }

        .subtitle {
          margin: 0;
          font-family: 'Calibri', Arial, sans-serif;
          font-weight: 400;
          cursor: pointer;
          font-size: 15px;
          color: #272727;
          display: inline;
          @media (max-width: 576px) {
            color: white;
          }
        }

        #cart-total {
          margin: 6px 0 6px;
          font-family: 'Calibri', Arial, sans-serif;
          font-weight: 400;
          font-size: 14px;
          color: #272727;
          @media (max-width: 576px) {
            color: white;
          }
        }

        #cart {
          margin: 6px 0 6px;
          font-family: 'Calibri', Arial, sans-serif;
          font-weight: 400;
          cursor: pointer;
          font-size: 15px;
          color: #272727;
          display: inline;

          button {
            text-shadow: none;
            box-shadow: none;
            text-align: left;
            @media (max-width: 576px) {
              text-align: center;
            }

            &:hover {
              background: transparent;
            }
          }
        }
      }

      .search {
        display: block;
        float: left;
        margin: 17px 0 0 15px;
        @media (max-width: 576px) {
          margin: 0;
        }

        &__button {
          display: none;
          background: #DA251C;
          cursor: pointer;
          @media(max-width: 576px) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 46px;
          }

          i {
            color: #fff;
            font-size: 24px;
            font-weight: bold;
          }
        }

        .input-group {
          @media (max-width: 576px) {
            display: none;
          }

          input {
            width: 250px;
            height: 30px;
            margin: 0;
            padding: 0 47px 0 15px;
            box-sizing: border-box;
            border: 1px solid #ed1c24;
            border-radius: 5px;
            text-align: left;
            background: #fff;
            outline: none;
            font-size: 16px;
            color: #272727;
          }

          span {
            button {
              display: block;
              height: 28px;
              width: auto;
              border-radius: 4px;
              background: linear-gradient(to bottom, rgba(251, 182, 98, 1) 0%, rgba(238, 29, 36, 1) 100%);
              border: none;
              outline: none !important;
              padding: 0 13px;
              color: #fff;
              position: absolute;
              top: 1px;
              right: 1px;
              transition: .15s;
              z-index: 10;
              text-transform: uppercase;
              font-size: 14px;
            }
          }
        }
      }

      .phone {
        background: #EB891A;
        display: none;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        @media (max-width: 576px) {
          display: flex;
        }

        .mobileContent {
          display: none;
          margin-bottom: 10px;
          .tel, .email {
            a {
              display: block;
              font-size: 20px;
              color: #fff;
              text-align: center;
            }
          }
          button {
            margin-top: 30px;
            background: red;
            border: none;
            font-size: 20px;
            color: white;
          }
        }

        &__button {
          height: 46px;
          display: flex;
          align-items: center;

          i {
            color: #fff;
            font-size: 24px;
          }
        }
      }
    }
  }
}


#marquee::before, #marquee::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
  z-index: 10;
}

.simplemarquee-wrapper {
  animation-name: simplemarquee-19b18b49cfd;
  animation-duration: 29.78s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;

  span:nth-child(1) {
    color: rgb(255, 0, 0);
  }

  span:nth-child(2) {
    color: rgb(0, 128, 0);
  }

  span:nth-child(3) {
    color: rgb(0, 255, 0);
  }

  span:nth-child(4) {
    color: rgb(0, 0, 255);
  }

  span:nth-child(5) {
    color: rgb(128, 0, 128);
  }
}

@-webkit-keyframes simplemarquee-19b18b49cfd {
  0% {
    -webkit-transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-1489px, 0);
  }
}

@-moz-keyframes simplemarquee-19b18b49cfd {
  0% {
    -moz-transform: translate(0, 0);
  }
  100% {
    -moz-transform: translate(-1489px, 0);
  }
}

@-o-keyframes simplemarquee-19b18b49cfd {
  0% {
    -o-transform: translate(0, 0);
  }
  100% {
    -o-transform: translate(-1489px, 0);
  }
}

@keyframes simplemarquee-19b18b49cfd {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-1489px, 0);
  }
}

.callbackModal {
  max-width: 266px !important;

  &__wrap {
    padding: 15px;

    &-title {
      font-size: 26px;
      font-weight: 400;
      color: #cd152c;
      margin: 0 0 10px;
      font-family: Arial, sans-serif;
    }

    &-form {
      form {
        display: flex;
        flex-direction: column;

        input {
          width: 236px;
          margin: 5px auto;
          padding: 7px 10px;
          box-sizing: border-box;
          height: 34px;
          font-size: 13px;
          color: #999798;
          border: 1px solid #999798;
          outline: none;
        }

        input.error {
          border: 1px solid red;
        }

        div.error {
          color: red;
          font-size: 10px;

          div {
            margin-bottom: 3px;
          }
        }

        button {
          color: #fff;
          outline: none;
          margin: 15px auto 10px;
          border: 1px solid #da6702;
          font-size: 17px;
          font-weight: 400;
          font-family: Arial, sans-serif;
          background: linear-gradient(to bottom, rgba(253, 152, 51, 1) 0%, rgba(236, 60, 2, 1) 100%);
          width: 188px;
          height: 35px;
        }
      }
    }
  }
}

.columnLeft {
  @media (max-width: 768px) {
    display: none;
  }
}

#search {
  @media (max-width: 576px) {
    background: #DA251C;
    height: 50px;
    width: 100%;

  }
}

.search {
  &__wrap {
    display: flex;
    justify-content: center;
  }
}
