.btn {
  display: inline-block;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
}

.btn-link {
  position: relative;
  font-size: 14px;
  color: $c-white;

  span {
    opacity: .6;
  }

  &:not(:last-child) {

    &::after {
      content: '| ';
      font-size: 14px;
      color: $c-white;
    }
  }
}

html[lang="ua"] {
  .btn-link[name="uk-ua"] span {
    opacity: 1;
  }
}


html[lang="ru"] {
  .btn-link[name="ru-ru"] span {
    opacity: 1;
  }
}

.btn-main {
  padding: 15px 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  text-align: center;
  color: $c-white;
  border: 2px solid transparent;
  background-color: $c-red;
  border-radius: 33px;
  transition: $transition;

  @include tablet {
    padding: 12px 34px;
    font-size: 14px;
  }

  &:hover {
    color: $c-red;
    background-color: $c-white;
  }

  &--bordered {
    border-color: $c-red;

    &:hover {
      background-color: transparent;
      color: $c-red;
    }
  }
  &[disabled],
  &--disabled {
    border-color: $c-label;
    background-color: $c-label;
    cursor: default;

    &:hover {
      border-color: $c-label;
      background-color: $c-label;
      color: $c-white;
      cursor: default;
    }
  }
}

.btn-border {
  display: inline-flex;
  align-items: center;
  border: 1px solid $c-white;
  height: 40px;
  border-radius: 20px;
  padding: 0 25px;
  font-size: 14px;
  color: $c-white;
  background-color: transparent;
  transition: all .2s;

  &:hover {
    border-color: $c-red;
    background-color: $c-red;
  }
}

.btn-more {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $c-red;
  background-color: $c-red;
  border-radius: 0 0 6px 6px;
  color: $c-white;
  font-size: 14px;

  @include tablet {
    background-color: $c-white;
    color: $c-red;
  }

  &:hover {
    background-color: $c-white;
    color: $c-red;

    @include tablet {
      background-color: $c-red;
      color: $c-white;
    }
  }
}

.btn-arrow {
  position: relative;
  width: 26px;
  height: 26px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 12px;
    height: 12px;
    border-style: solid;
    border-color: $c-red;
  }

  &--prev {
    &::before {
      border-width: 0 0 1px 1px;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &--next {
    &::before {
      border-width: 0 1px 1px 0;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

}

.btn-scroll {
  position: absolute;
  bottom: 0;
  right: calc((100vw - 1250px) / 2);
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: $c-white;
  background: transparent;
  border: 0;
  transform: rotate(90deg);
  transform-origin: right bottom;
  transition: color .3s;
  z-index: 1;

  @include laptop {
    right: calc((100vw - 1100px) / 2);
  }
  @include tablet {
    right: 30px;
  }
  @include mobile {
    display: none;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    color: $c-red;
    .btn-scroll__line:before {
      background-color: $c-red;
    }
  }

  &__line {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 21px;
    min-width: 160px;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $c-white;
      animation: scrollDown 3s ease infinite;
      transition: background-color .3s;
    }
  }
}

@keyframes scrollDown {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  49.9% {
    transform: scaleX(1);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: right;
  }
  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}

.btn-simple {
  font-size: 16px;
  color: $c-primary;
  opacity: .5;
  text-decoration: underline;
  transition: opacity .3s ease-in-out;

  &:hover {
    opacity: 1;
    transition: opacity .3s ease-in-out;
  }
}

.btn-collapse {
  width: 16px;
  height: 16px;
  border-style: solid;
  border-color: $c-silver;
  border-width: 0 0 1px 1px;
  transform: rotate(-45deg) translate(5px, -10px);
  transform-origin: center;
  transition: transform .3s ease-in-out;

  &.active {
    transform: rotate(-225deg) translate(0, 5px);
    transition: transform .3s ease-in-out;
  }
}
