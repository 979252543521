@import "./helpers/variables";
@import "./helpers/mixins";
@import "./helpers/fonts";
@import "./helpers/misc";
@import "./helpers/buttons";
@import "./helpers/typography";

@import "./common/global";
@import "~izimodal/css/iziModal.min.css";
@import "~owl.carousel/dist/assets/owl.carousel.min.css";
@import "~owl.carousel/dist/assets/owl.theme.default.min.css";
@import "~slick-carousel/slick/slick";

@import "./layout/_footer.scss"; @import "./layout/_header.scss";
@import "./components/_blog.scss"; @import "./components/_catalog.scss"; @import "./components/_global.scss"; @import "./components/_latestProduct.scss"; @import "./components/_singleProduct.scss";
