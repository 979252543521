@charset "UTF-8";

/* responsive */

@import url(~izimodal/css/iziModal.min.css);

@import url(~owl.carousel/dist/assets/owl.carousel.min.css);

@import url(~owl.carousel/dist/assets/owl.theme.default.min.css);

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  max-width: 100%;
  height: auto;
}

input,
textarea,
button {
  font-family: inherit;
}

.btn {
  display: inline-block;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.btn:focus {
  outline: 0;
}

.btn-link {
  position: relative;
  font-size: 14px;
  color: #FFFFFF;
}

.btn-link span {
  opacity: .6;
}

.btn-link:not(:last-child)::after {
  content: '| ';
  font-size: 14px;
  color: #FFFFFF;
}

html[lang="ua"] .btn-link[name="uk-ua"] span {
  opacity: 1;
}

html[lang="ru"] .btn-link[name="ru-ru"] span {
  opacity: 1;
}

.btn-main {
  padding: 15px 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  text-align: center;
  color: #FFFFFF;
  border: 2px solid transparent;
  background-color: #FD2937;
  border-radius: 33px;
  transition: all 0.3s ease;
}

@media (max-width: 1199px) {
  .btn-main {
    padding: 12px 34px;
    font-size: 14px;
  }
}

.btn-main:hover {
  color: #FD2937;
  background-color: #FFFFFF;
}

.btn-main--bordered {
  border-color: #FD2937;
}

.btn-main--bordered:hover {
  background-color: transparent;
  color: #FD2937;
}

.btn-main[disabled],
.btn-main--disabled {
  border-color: #8A8A8A;
  background-color: #8A8A8A;
  cursor: default;
}

.btn-main[disabled]:hover,
.btn-main--disabled:hover {
  border-color: #8A8A8A;
  background-color: #8A8A8A;
  color: #FFFFFF;
  cursor: default;
}

.btn-border {
  display: inline-flex;
  align-items: center;
  border: 1px solid #FFFFFF;
  height: 40px;
  border-radius: 20px;
  padding: 0 25px;
  font-size: 14px;
  color: #FFFFFF;
  background-color: transparent;
  transition: all .2s;
}

.btn-border:hover {
  border-color: #FD2937;
  background-color: #FD2937;
}

.btn-more {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #FD2937;
  background-color: #FD2937;
  border-radius: 0 0 6px 6px;
  color: #FFFFFF;
  font-size: 14px;
}

@media (max-width: 1199px) {
  .btn-more {
    background-color: #FFFFFF;
    color: #FD2937;
  }
}

.btn-more:hover {
  background-color: #FFFFFF;
  color: #FD2937;
}

@media (max-width: 1199px) {
  .btn-more:hover {
    background-color: #FD2937;
    color: #FFFFFF;
  }
}

.btn-arrow {
  position: relative;
  width: 26px;
  height: 26px;
}

.btn-arrow::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 12px;
  height: 12px;
  border-style: solid;
  border-color: #FD2937;
}

.btn-arrow--prev::before {
  border-width: 0 0 1px 1px;
  transform: translate(-50%, -50%) rotate(45deg);
}

.btn-arrow--next::before {
  border-width: 0 1px 1px 0;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.btn-scroll {
  position: absolute;
  bottom: 0;
  right: calc((100vw - 1250px) / 2);
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #FFFFFF;
  background: transparent;
  border: 0;
  transform: rotate(90deg);
  transform-origin: right bottom;
  transition: color .3s;
  z-index: 1;
}

@media (max-width: 1439px) {
  .btn-scroll {
    right: calc((100vw - 1100px) / 2);
  }
}

@media (max-width: 1199px) {
  .btn-scroll {
    right: 30px;
  }
}

@media (max-width: 767px) {
  .btn-scroll {
    display: none;
  }
}

.btn-scroll:focus {
  outline: 0;
}

.btn-scroll:hover {
  color: #FD2937;
}

.btn-scroll:hover .btn-scroll__line:before {
  background-color: #FD2937;
}

.btn-scroll__line {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 21px;
  min-width: 160px;
}

.btn-scroll__line:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #FFFFFF;
  animation: scrollDown 3s ease infinite;
  transition: background-color .3s;
}

@keyframes scrollDown {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }

  49.9% {
    transform: scaleX(1);
    transform-origin: left;
  }

  50% {
    transform: scaleX(1);
    transform-origin: right;
  }

  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}

.btn-simple {
  font-size: 16px;
  color: #242222;
  opacity: .5;
  text-decoration: underline;
  transition: opacity .3s ease-in-out;
}

.btn-simple:hover {
  opacity: 1;
  transition: opacity .3s ease-in-out;
}

.btn-collapse {
  width: 16px;
  height: 16px;
  border-style: solid;
  border-color: #C4C4C4;
  border-width: 0 0 1px 1px;
  transform: rotate(-45deg) translate(5px, -10px);
  transform-origin: center;
  transition: transform .3s ease-in-out;
}

.btn-collapse.active {
  transform: rotate(-225deg) translate(0, 5px);
  transition: transform .3s ease-in-out;
}

.h1,
.h2 {
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.h1 {
  font-size: 54px;
  line-height: 1.26;
}

@media (max-width: 1199px) {
  .h1 {
    font-size: 30px;
    line-height: 1.35;
  }
}

@media only screen and (max-width: 374px) {
  .h1 {
    font-size: 26px;
    line-height: 1.56;
  }
}

.h2 {
  font-size: 38px;
  line-height: 1.39;
}

@media (max-width: 1199px) {
  .h2 {
    font-size: 18px;
    line-height: 1.56;
  }
}

.h3 {
  margin: 0;
  font-size: 24px;
  line-height: 1.68;
}

@media (max-width: 1199px) {
  .h3 {
    font-size: 18px;
  }
}

.heading-modal {
  margin: 0;
  font-size: 32px;
  line-height: 1.39;
}

@media (max-width: 1199px) {
  .heading-modal {
    font-size: 18px;
    line-height: 1.56;
  }
}

.heading-article {
  font-size: 31px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  color: #FD2937;
}

@media (max-width: 1199px) {
  .heading-article {
    font-size: 20px;
    line-height: 1.5;
  }
}

.c-white {
  color: #FFFFFF;
}

.c-black {
  color: #000000;
}

.c-red {
  color: #FD2937;
}

.c-primary {
  color: #242222;
}

.header__nav {
  position: relative;
}

#form-language.active .dropdown-toggle {
  box-shadow: none;
}

#form-language.active .list {
  display: block !important;
}

.langSwitcher {
  position: absolute;
  right: 5px;
}

.langSwitcher .dropdownLang {
  border: none;
  background: none;
  padding: 5px;
  background: #4d82c1;
  color: white;
}

.langSwitcher .list {
  left: 4px;
  position: relative;
}

.langSwitcher .language-select {
  padding: 0;
  border: none;
  margin-top: 5px;
  background: none;
}

.langSwitcher .btn-link {
  color: black;
}

.langSwitcher .btn-group .list {
  display: none;
}

.inactive {
  opacity: 0.65;
  filter: blur(1px);
}

#filter_vier {
  width: 222px;
  background: #f7f6f6;
  box-shadow: 0px 2px 9px 0px rgba(79, 78, 78, 0.75);
  padding: 7px 10px 7px 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

#filter_vier .title_p_f,
#filter_vier .text_param {
  color: #696868;
}

.miniTitle {
  font-size: 14px;
  margin-left: 20px;
  color: #ac0607;
  margin-top: 10px;
}

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.footer {
  padding: 10px 0;
  background: #f7f6f6;
  font-size: 14px;
  color: #333;
}

@media (max-width: 576px) {
  .footer {
    text-align: center;
  }
}

.footer ul {
  padding: 0;
  margin: 0;
}

.footer ul li {
  list-style-type: none;
}

.footer__wrap .container {
  padding: 20px;
}

.footer__wrap .container .row {
  margin: 0;
}

.footer__wrap-contact .copyright,
.footer__wrap-contact .title,
.footer__wrap-contact .phone,
.footer__wrap-contact .address {
  margin-bottom: 10px;
}

.footer__wrap-contact .address,
.footer__wrap-contact .grafic {
  font-weight: 700;
}

.footer__wrap-contact .phone ul li a {
  font-weight: 700;
  color: #333;
  margin-left: 2px;
}

.footer__wrap-contact .phone ul li a:hover {
  color: #333;
}

.footer__wrap-contact .phone ul li:first-child {
  margin-bottom: 10px;
}

.footer__wrap-nav {
  padding-top: 7px;
  padding-bottom: 7px;
}

.footer__wrap-nav a {
  font-size: 14px;
  font-family: Arial, sans-serif;
  color: #696868;
}

.footer__wrap-nav a:hover {
  text-decoration: underline;
  color: red;
}

.footer__wrap-info {
  padding-top: 11px;
  padding-bottom: 11px;
}

.footer__wrap-info .social__title,
.footer__wrap-info .payment__title {
  font-family: Arial, sans-serif;
  color: #656565;
  font-size: 14px;
  margin: 0;
}

.footer__wrap-info ul {
  padding: 0;
  margin: 14px 0 19px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 576px) {
  .footer__wrap-info ul {
    justify-content: center;
  }
}

.footer__wrap-info ul li {
  margin-right: 5px;
}

.footer__wrap-info ul li a {
  margin: 0;
  opacity: 1;
  transition: .15s;
}

.footer__wrap-info ul li a:hover {
  opacity: .7;
}

.footer__wrap-info ul li a img {
  width: 32px;
  height: 32px;
  display: block;
  backface-visibility: hidden;
}

.chat-circle {
  position: fixed;
  z-index: 999;
  bottom: 75px;
  left: 20px;
}

.chat-circle.active ul {
  visibility: visible;
  opacity: 1;
}

.chat-circle.active i.fa-times {
  display: block;
}

.chat-circle.active i.fa-comments {
  display: none;
}

.chat-circle ul {
  padding: 0;
  margin: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.chat-circle ul li {
  margin-bottom: 10px;
}

.chat-circle span,
.chat-circle li {
  background: #000;
  opacity: .5;
  color: #fff;
  outline: none;
  border: 1px solid #000;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.chat-circle span i,
.chat-circle li i {
  font-size: 30px;
}

.chat-circle span i.fa-times,
.chat-circle li i.fa-times {
  display: none;
}

.chat-circle span img,
.chat-circle li img {
  width: 40px;
  height: 40px;
}

.header__redline {
  height: 4px;
  width: 100%;
  background: #fc0000;
}

.header__nav-button {
  display: none;
  width: 24px;
  height: 24px;
  background: #9a0308;
}

@media (max-width: 576px) {
  .header__nav-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    justify-content: center;
    height: 46px;
  }
}

.header__nav-button span {
  background: #fff;
  display: block;
  width: 24px;
  height: 3px;
  border-radius: 3px;
  position: relative;
}

.header__nav-button span:before,
.header__nav-button span:after {
  content: '';
  display: block;
  background: #fff;
  width: 24px;
  height: 3px;
  border-radius: 3px;
  position: absolute;
}

.header__nav-button span:before {
  top: -8px;
}

.header__nav-button span:after {
  top: 8px;
}

.header__nav ul {
  margin: 0 10px 0 217px;
  padding: 0;
  display: block;
  float: left;
  max-width: 650px;
  list-style: none;
  text-align: center;
}

@media (max-width: 768px) {
  .header__nav ul {
    max-width: 550px;
    margin: 0 auto;
  }
}

@media (max-width: 576px) {
  .header__nav ul {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
  }
}

.header__nav ul li {
  margin: 0 7px;
  padding: 0;
  display: inline-block;
  position: relative;
  padding-top: 9px;
}

.header__nav ul li:before {
  display: block;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 4.5px 0 4.5px;
  border-color: #fc0000 transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -5px;
}

@media (max-width: 576px) {
  .header__nav ul li:before {
    display: none;
  }
}

.header__nav ul li a {
  display: block;
  font-family: 'Calibri', Arial, sans-serif;
  font-size: 14px;
  padding: 0 0 1px;
  text-transform: uppercase;
  color: #424141;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: .15s;
}

@media (max-width: 576px) {
  .header__nav ul li a {
    color: white;
    text-align: center;
    font-size: 20px;
    text-decoration: underline;
    text-transform: none;
  }
}

@media (max-width: 576px) {
  .header__nav nav {
    display: none;
    background: #9a0308;
  }
}

.header__nav nav .callback {
  background: url("/catalog/view/app/assets/img/callback.png") no-repeat;
  width: 180px;
  height: 37px;
  display: block;
  position: relative;
  top: -4px;
  margin-bottom: -4px;
  outline: none !important;
  opacity: 1;
  transition: .2s;
  border: none;
  cursor: pointer;
  float: right;
}

@media (max-width: 768px) {
  .header__nav nav .callback {
    top: -56px;
  }
}

@media (max-width: 576px) {
  .header__nav nav .callback {
    display: none;
  }
}

.header__content {
  display: flex;
  padding-bottom: 30px;
}

@media (max-width: 768px) {
  .header__content {
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .header__content-logo {
    display: none;
  }
}

.header__content-logo a {
  display: block;
  float: left;
  margin: 0 31px;
  position: relative;
  top: -23px;
  margin-bottom: -23px;
}

.header__content-logo a img {
  display: block;
  width: 146px;
  height: 166px;
  object-fit: contain;
}

.header__content-title {
  display: block;
  float: left;
  width: 567px;
  text-align: right;
}

@media (max-width: 768px) {
  .header__content-title {
    width: 50%;
  }
}

@media (max-width: 576px) {
  .header__content-title {
    display: none;
  }
}

.header__content-title .mainTitle img {
  width: 100%;
  margin-top: 8px;
}

.header__content-title .line {
  overflow-wrap: normal;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 0 0 10px;
  height: 1.5em;
  line-height: 1.5em;
  position: relative;
  display: block;
}

@media (max-width: 576px) {
  .header__content-cart {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
}

.header__content-cart .content {
  display: flex;
  align-items: center;
  padding: 19px 0 0 48px;
}

@media (max-width: 576px) {
  .header__content-cart .content {
    padding: 0;
    flex-direction: column;
  }
}

.header__content-cart .content__button {
  display: none;
  background: #9a0308;
  cursor: pointer;
}

@media (max-width: 576px) {
  .header__content-cart .content__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    width: 100%;
  }
}

.header__content-cart .content__button i {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

.header__content-cart .content img {
  width: 68px;
  height: 75px;
  float: left;
  display: block;
  margin-right: 8px;
}

@media (max-width: 576px) {
  .header__content-cart .content img {
    display: none;
  }
}

.header__content-cart .data {
  display: flex;
  flex-direction: column;
}

@media (max-width: 576px) {
  .header__content-cart .data {
    display: none;
    width: 100%;
  }
}

.header__content-cart .data__wrap {
  display: flex;
  flex-direction: column;
}

@media (max-width: 576px) {
  .header__content-cart .data__wrap {
    padding: 10px;
    background: #9a0308;
    text-align: center;
  }
}

.header__content-cart .data .title {
  margin: 0;
  font-family: 'Calibri', Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  color: #272727;
}

@media (max-width: 576px) {
  .header__content-cart .data .title {
    color: white;
  }
}

.header__content-cart .data .subtitle {
  margin: 0;
  font-family: 'Calibri', Arial, sans-serif;
  font-weight: 400;
  cursor: pointer;
  font-size: 15px;
  color: #272727;
  display: inline;
}

@media (max-width: 576px) {
  .header__content-cart .data .subtitle {
    color: white;
  }
}

.header__content-cart .data #cart-total {
  margin: 6px 0 6px;
  font-family: 'Calibri', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #272727;
}

@media (max-width: 576px) {
  .header__content-cart .data #cart-total {
    color: white;
  }
}

.header__content-cart .data #cart {
  margin: 6px 0 6px;
  font-family: 'Calibri', Arial, sans-serif;
  font-weight: 400;
  cursor: pointer;
  font-size: 15px;
  color: #272727;
  display: inline;
}

.header__content-cart .data #cart button {
  text-shadow: none;
  box-shadow: none;
  text-align: left;
}

@media (max-width: 576px) {
  .header__content-cart .data #cart button {
    text-align: center;
  }
}

.header__content-cart .data #cart button:hover {
  background: transparent;
}

.header__content-cart .search {
  display: block;
  float: left;
  margin: 17px 0 0 15px;
}

@media (max-width: 576px) {
  .header__content-cart .search {
    margin: 0;
  }
}

.header__content-cart .search__button {
  display: none;
  background: #DA251C;
  cursor: pointer;
}

@media (max-width: 576px) {
  .header__content-cart .search__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
  }
}

.header__content-cart .search__button i {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

@media (max-width: 576px) {
  .header__content-cart .search .input-group {
    display: none;
  }
}

.header__content-cart .search .input-group input {
  width: 250px;
  height: 30px;
  margin: 0;
  padding: 0 47px 0 15px;
  box-sizing: border-box;
  border: 1px solid #ed1c24;
  border-radius: 5px;
  text-align: left;
  background: #fff;
  outline: none;
  font-size: 16px;
  color: #272727;
}

.header__content-cart .search .input-group span button {
  display: block;
  height: 28px;
  width: auto;
  border-radius: 4px;
  background: linear-gradient(to bottom, #fbb662 0%, #ee1d24 100%);
  border: none;
  outline: none !important;
  padding: 0 13px;
  color: #fff;
  position: absolute;
  top: 1px;
  right: 1px;
  transition: .15s;
  z-index: 10;
  text-transform: uppercase;
  font-size: 14px;
}

.header__content-cart .phone {
  background: #EB891A;
  display: none;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 576px) {
  .header__content-cart .phone {
    display: flex;
  }
}

.header__content-cart .phone .mobileContent {
  display: none;
  margin-bottom: 10px;
}

.header__content-cart .phone .mobileContent .tel a,
.header__content-cart .phone .mobileContent .email a {
  display: block;
  font-size: 20px;
  color: #fff;
  text-align: center;
}

.header__content-cart .phone .mobileContent button {
  margin-top: 30px;
  background: red;
  border: none;
  font-size: 20px;
  color: white;
}

.header__content-cart .phone__button {
  height: 46px;
  display: flex;
  align-items: center;
}

.header__content-cart .phone__button i {
  color: #fff;
  font-size: 24px;
}

#marquee::before,
#marquee::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  background: -moz-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
  z-index: 10;
}

.simplemarquee-wrapper {
  animation-name: simplemarquee-19b18b49cfd;
  animation-duration: 29.78s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
}

.simplemarquee-wrapper span:nth-child(1) {
  color: red;
}

.simplemarquee-wrapper span:nth-child(2) {
  color: green;
}

.simplemarquee-wrapper span:nth-child(3) {
  color: lime;
}

.simplemarquee-wrapper span:nth-child(4) {
  color: blue;
}

.simplemarquee-wrapper span:nth-child(5) {
  color: purple;
}

@-webkit-keyframes simplemarquee-19b18b49cfd {
  0% {
    -webkit-transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(-1489px, 0);
  }
}

@-moz-keyframes simplemarquee-19b18b49cfd {
  0% {
    -moz-transform: translate(0, 0);
  }

  100% {
    -moz-transform: translate(-1489px, 0);
  }
}

@-o-keyframes simplemarquee-19b18b49cfd {
  0% {
    -o-transform: translate(0, 0);
  }

  100% {
    -o-transform: translate(-1489px, 0);
  }
}

@keyframes simplemarquee-19b18b49cfd {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-1489px, 0);
  }
}

.callbackModal {
  max-width: 266px !important;
}

.callbackModal__wrap {
  padding: 15px;
}

.callbackModal__wrap-title {
  font-size: 26px;
  font-weight: 400;
  color: #cd152c;
  margin: 0 0 10px;
  font-family: Arial, sans-serif;
}

.callbackModal__wrap-form form {
  display: flex;
  flex-direction: column;
}

.callbackModal__wrap-form form input {
  width: 236px;
  margin: 5px auto;
  padding: 7px 10px;
  box-sizing: border-box;
  height: 34px;
  font-size: 13px;
  color: #999798;
  border: 1px solid #999798;
  outline: none;
}

.callbackModal__wrap-form form input.error {
  border: 1px solid red;
}

.callbackModal__wrap-form form div.error {
  color: red;
  font-size: 10px;
}

.callbackModal__wrap-form form div.error div {
  margin-bottom: 3px;
}

.callbackModal__wrap-form form button {
  color: #fff;
  outline: none;
  margin: 15px auto 10px;
  border: 1px solid #da6702;
  font-size: 17px;
  font-weight: 400;
  font-family: Arial, sans-serif;
  background: linear-gradient(to bottom, #fd9833 0%, #ec3c02 100%);
  width: 188px;
  height: 35px;
}

@media (max-width: 768px) {
  .columnLeft {
    display: none;
  }
}

@media (max-width: 576px) {
  #search {
    background: #DA251C;
    height: 50px;
    width: 100%;
  }
}

.search__wrap {
  display: flex;
  justify-content: center;
}

.blog_item {
  padding: 0;
}

.blog_item img {
  display: block;
  width: 55px;
  height: 55px;
  transition: .2s;
  float: left;
  margin: 0 6px 0 0;
}

.blog_item .summary .blog_title a {
  font-size: 14px;
  color: #696868;
  font-family: Arial, sans-serif;
  font-weight: 400;
  display: block;
  padding-top: 3px;
}

.blog_item .summary .blog_title a:hover {
  color: #2389bf;
  text-decoration: underline;
}

.blog_item .summary p {
  font-size: 13px;
  font-family: Arial, sans-serif;
  margin: 5px 0;
  transition: .2s;
  line-height: 1.2;
}

.blog_grid_holder {
  display: block;
  text-decoration: none;
  color: #272727;
  text-align: justify;
  width: 222px;
  margin: 9px auto 0;
}

.bannerItem {
  margin: 20px auto;
  display: block;
  width: 192px;
  height: 492px;
}

.bannerItem img {
  display: block;
  margin: auto;
  width: 192px;
  height: 492px;
}

.blogLatest__more {
  text-align: right;
  padding-right: 5px;
  width: 222px;
  margin: 9px auto 0;
}

.moreTitle a {
  font-size: 12px;
  font-family: Arial, sans-serif;
  color: #ac0607;
}

.moreTitle a:hover {
  color: #e53b3c;
  text-decoration: underline;
}

#column-left .mebel_menu,
#column-left .catalog__list {
  margin: 0 auto;
  width: 222px;
  background: #f7f6f6;
  box-shadow: 0px 2px 9px 0px rgba(79, 78, 78, 0.75);
  padding: 7px 10px 7px 20px;
}

#column-left .mebel_menu ul,
#column-left .catalog__list ul {
  padding: 0;
  margin: 0;
}

#column-left .mebel_menu ul li,
#column-left .catalog__list ul li {
  list-style-type: none;
  padding: 0 0 0 12px;
  margin: 5px 0 0;
  position: relative;
}

#column-left .mebel_menu ul li a,
#column-left .catalog__list ul li a {
  font-size: 14px;
  font-family: Arial, sans-serif;
  color: #696868;
  text-decoration: none;
}

#column-left .mebel_menu ul li a:before,
#column-left .catalog__list ul li a:before {
  display: block;
  content: '';
  width: 7px;
  height: 7px;
  box-sizing: border-box;
  border: 1px solid #696868;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 0px;
}

.sidebar__title {
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #d7100e;
  text-transform: uppercase;
  margin: 23px 0 14px;
  text-align: center;
}

.catalog__list-item.active,
.catalog__list-item:hover {
  text-decoration: underline !important;
}

.mebel_menu-li.active,
.mebel_menu-li:hover {
  text-decoration: underline !important;
}

.category__description {
  margin-bottom: 40px;
}

.category__description.active .col-sm-10 {
  height: 100%;
}

.category__description.active .col-sm-10:after {
  display: none;
}

.category__description .col-sm-10 {
  height: 200px;
  overflow: hidden;
  position: relative;
}

.category__description .col-sm-10:after {
  content: '';
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 70%);
  transition: height .25s ease;
}

.category__description span.show-more {
  margin-top: 20px;
  margin-bottom: 40px;
  cursor: pointer;
  display: inline-block;
}

ul.pagination li a {
  color: #696868;
}

ul.pagination li.active span {
  background: linear-gradient(to bottom, #fd9833 0%, #ec3c02 100%);
  border-color: #f15810;
}

.productPagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productPagination .text-left {
  width: 66%;
}

.productPagination .text-right {
  width: 34%;
  margin: 20px 0;
}

.alert-success {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 0;
}

.alert-success .alertSuccess-wrap {
  background: white;
  padding: 6px;
  border-radius: 3px;
  max-width: 300px;
  border: 1px solid #f4691a;
  position: relative;
  font-size: 12px;
}

.alert-success a {
  color: #696868;
  text-decoration: underline;
}

.alert-success a:hover {
  color: #696868;
}

.alert-success button {
  position: absolute !important;
  top: 4px !important;
  right: 10px !important;
}

.blog .blog_grid_holder {
  width: 100%;
}

.block_param .title_filter {
  font-weight: 500;
  font-size: 14px;
}

.block_param .skrit {
  display: block !important;
}

.block_param .skrit .row_blok a {
  font-size: 14px;
  font-family: Arial, sans-serif;
  color: #696868;
}

.productSearch {
  margin-bottom: 40px;
}

.productSearch .row {
  margin-bottom: 10px;
}

.productSearch input,
.productSearch select {
  border: 1px solid #f26115;
  outline: none;
  cursor: pointer;
}

.productSearch input.btn {
  width: 99px;
  height: 35px;
  float: left;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #fd9833 0%, #ec3c02 100%);
  color: #fff;
  outline: none;
  border: 1px solid #da6702;
  font-size: 17px;
  font-weight: 400;
  font-family: Arial, sans-serif;
}

.productSearch .checkbox-inline input {
  margin-top: 0;
}

#error-not-found p {
  font-size: 14px;
  font-family: Arial, sans-serif;
  color: #696868;
}

#error-not-found .pull-right {
  margin-top: 20px;
  float: left !important;
}

#error-not-found .pull-right a {
  float: left;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #fd9833 0%, #ec3c02 100%);
  color: #fff;
  outline: none;
  border: 1px solid #da6702;
  font-size: 17px;
  font-weight: 400;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  padding: 2px 14px;
}

.popularProduct__wrap {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.popularProduct__wrap .owl-carousel {
  width: 720px;
  margin: 10px 0 0;
  float: left;
}

.popularProduct__wrap .owl-carousel .owlItem {
  margin: 0 6px;
}

.popularProduct__wrap .owl-carousel .owl-item a {
  font-size: 14px;
  color: #696868;
  font-family: Arial, sans-serif;
  text-align: center;
  display: block;
}

.popularProduct__wrap .owl-carousel .owl-item a span {
  margin: 9px;
  min-height: 32px;
  display: block;
}

.popularProduct__wrap .control {
  width: 40px;
  float: left;
}

.popularProduct__wrap .control button {
  display: block;
  border: none;
  outline: none;
  background: url(/catalog/view/app/assets/img/arrow.png) no-repeat;
  width: 15px;
  height: 47px;
  margin: auto;
}

.popularProduct__wrap .next_button {
  transform: scale(-1, 1);
}

.article {
  font: 400 15px Arial, arial, sans-serif;
  color: #656565;
  padding: 10px 5px;
  font-weight: 700;
}

.contactContent {
  display: flex;
}

.contactContent .info__store,
.contactContent .info__address,
.contactContent .info__tel {
  font-size: 16px;
}

.contactContent .info__tel {
  margin-bottom: 10px;
}

.contactContent .info__tel a {
  color: #656565;
}

.contactContent .info__email {
  margin-bottom: 18px;
}

.contactContent .info__email a {
  font-size: small;
  color: #656565;
}

.contactContent .info__open {
  font-size: 16px;
}

.contactLink a {
  color: #337ab7;
  text-decoration: none;
  font-size: 16px;
  display: block;
}

.contactLink a:hover {
  color: #23527c;
  text-decoration: underline;
}

.contactLink a:first-child {
  margin-bottom: 18px;
}

.pageBlog .blog_item {
  display: flex;
  margin-bottom: 20px;
}

.pageBlog .blog_item .img {
  margin-right: 6px;
}

.pageBlog .blog_item .img img {
  object-fit: cover;
}

.pageBlog .blog_item .description h2 {
  margin: 0;
}

.pageBlog .blog_item .description h2 a {
  color: #696868;
  font-size: 14px;
}

.pageBlog .blog_item .description h2 a:hover {
  color: #cd152c;
  text-decoration: underline;
}

#form-testimonial #button-testimonial {
  background: linear-gradient(to bottom, #fd9833 0%, #ec3c02 100%);
  border-color: #f15810;
  font-size: 17px;
  font-weight: 400;
  padding: 2px 14px;
}

@media (max-width: 576px) {
  #column-left {
    display: none !important;
  }
}

.seoText {
  padding: 0 15px;
  margin-bottom: 20px;
}

.so-onepagecheckout .checkout-cart tbody tr td a.product-name {
  white-space: break-spaces;
}

#simplecheckout_button_cart span {
  background: #da251c;
  padding: 7px;
}

.simplecheckout-cart .quantity .btn.btn-danger:hover,
.simplecheckout-cart .quantity .btn.btn-primary:hover {
  background: transparent !important;
  box-shadow: none !important;
}

.simplecheckout-cart .quantity .fa-minus,
.simplecheckout-cart .quantity .fa-plus {
  color: black;
  margin: 0 4px;
}

.simplecheckout-cart .quantity .fa-times-circle {
  color: #da251c;
  margin-left: 10px;
}

#simplecheckout_button_confirm span {
  background: #da251c;
  padding: 15px;
  font-size: 15px;
}

#button-confirm {
  background: #da251c;
  padding: 15px;
  font-size: 15px;
}

.checkoutsimplecheckout h1 {
  margin-bottom: 30px;
}

.latestProduct__list .row {
  width: 100%;
  margin: 0;
}

.productsWrap {
  padding: 9px 0;
  margin: 0;
}

.pageHome__wrap .content {
  padding: 0;
}

.productsTitle {
  color: #ac0607;
  font-size: 25px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 30px;
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}

.blockTitle {
  color: #ac0607;
  font-size: 25px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 30px;
  margin: 6px 0;
}

.product-layout {
  padding: 0;
  margin: 5px 0 0;
  border: 1px solid #c9c8c8;
}

@media (max-width: 576px) {
  .product-layout {
    max-width: 344px;
    margin: 5px auto 0 auto;
    float: inherit;
  }
}

.product-layout:hover {
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.75);
  transition: .15s;
}

.product-layout .product-thumb .image a {
  display: block;
  width: 189px;
  height: 189px;
  margin: 6px auto 8px;
}

.product-layout .product-thumb .image a img {
  display: block;
  width: 189px;
  height: 189px;
  margin: auto;
}

.product-layout .product-thumb .caption a {
  font-size: 15px;
  color: #696868;
  font-family: Arial, sans-serif;
  font-weight: 400;
  margin: 0;
  height: 45px;
  text-decoration: none;
  text-align: center;
  display: block;
}

.product-layout .product-thumb .caption a:hover {
  text-decoration: underline;
}

.product-layout .product-thumb .caption p.description {
  display: none;
}

.product-layout .product-thumb .button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-layout .product-thumb .button-group .price {
  margin: 0;
  font-size: 24px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  color: #d7100e;
  width: 56%;
  text-align: center;
}

.product-layout .product-thumb .button-group button {
  width: 99px;
  height: 35px;
  float: left;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #fd9833 0%, #ec3c02 100%);
  color: #fff;
  outline: none;
  border: 1px solid #da6702;
  font-size: 17px;
  font-weight: 400;
  font-family: Arial, sans-serif;
}

.product-layout .product-thumb .button-group button span {
  display: block !important;
}

.product-layout .product-thumb .stock {
  margin: 6px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #18bc37;
  font-size: 13px;
  font-family: Arial, sans-serif;
  min-height: 32px;
}

.breadcrumb {
  background: transparent;
  padding: 0 30px;
}

.breadcrumb li:before {
  content: '>>' !important;
  color: #656565 !important;
  position: relative;
  top: -1px;
}

.breadcrumb li:first-child:before {
  content: '' !important;
}

.breadcrumb li:last-child a {
  color: #ac0607;
}

.breadcrumb a {
  color: #656565;
  font-size: 11px;
  font-family: Arial, sans-serif;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.breadcrumb.productCategory {
  padding: 0;
}

.singleProduct__content {
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.75);
  border: 1px solid #c9c8c8;
  margin: 0;
  display: flex;
}

@media (max-width: 576px) {
  .singleProduct__content {
    flex-direction: column;
  }
}

.singleProduct__content-left {
  min-height: 376px;
  text-align: center;
  position: relative;
  padding: 0;
  border-right: 1px solid #c9c8c8;
}

.singleProduct__content-left .wrap {
  padding: 15px;
  border-bottom: 1px solid #c9c8c8;
}

.singleProduct__content-left .wrap .img {
  margin-bottom: 40px;
}

.singleProduct__content-left .tags {
  padding: 15px;
}

.singleProduct__content-left .thumbnail {
  padding: 0;
  margin: 0;
  border: none;
}

.singleProduct__content-left .slider-for {
  margin-bottom: 20px;
}

.singleProduct__content-left .slider-for img {
  display: block;
  width: 346px;
  height: 346px;
  margin: auto;
  object-fit: contain;
}

.singleProduct__content-left .slider-nav {
  display: flex;
  align-items: center;
}

.singleProduct__content-left .slider-nav ul {
  width: 100%;
}

.singleProduct__content-left .slider-nav ul .slick-track {
  width: 100% !important;
}

.singleProduct__content-left .slider-nav ul .slick-slide {
  width: auto !important;
}

.singleProduct__content-left .slider-nav ul li img {
  display: block;
  width: 71px;
  height: 71px;
  margin: auto;
  cursor: pointer;
}

.singleProduct__content-left .slider-nav .slick-arrow {
  cursor: pointer;
}

.singleProduct__content-left .slider-nav .next img {
  transform: scale(-1, 1);
}

.singleProduct__content-right {
  max-width: 396px;
  padding: 32px;
}

.singleProduct__content-right .title {
  margin: 0;
  font-weight: 400;
  font-size: 22px;
  color: #696868;
  font-family: Arial, sans-serif;
}

.singleProduct__content-right .article {
  margin: 13px 0 23px;
  font-size: 13px;
  color: #ac0607;
  font-family: Arial, sans-serif;
  font-weight: 400;
}

.singleProduct__content-right .article span {
  color: #272727;
}

.singleProduct__content-right .faq h3 {
  font-size: 24px;
  color: #333333;
}

.singleProduct__content-right .faq .text {
  font-size: 14px;
  color: #333;
}

.singleProduct__content-right .faq a {
  color: #337ab7;
  text-decoration: none;
  font-size: 14px;
}

.singleProduct__content-right .faq a:hover {
  color: #23527c;
  text-decoration: underline;
}

.singleProduct__content-right .inStock {
  text-align: center;
  color: #18bc37;
  margin: 17px 0 0;
}

.singleProduct__content-right .inStock img {
  margin: 6px auto;
}

.singleProduct__content-right .price {
  display: flex;
  align-items: center;
  max-width: 240px;
  justify-content: space-between;
  margin: 0 auto;
}

.singleProduct__content-right .price ul li h2 {
  margin: 0;
  padding-top: 5px;
  font-size: 36px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  color: #d7100e;
}

.singleProduct__content-right .price #product button {
  width: 99px;
  height: 35px;
  display: block;
  float: right;
  margin: 8px 0;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #fd9833 0%, #ec3c02 100%);
  color: #fff;
  outline: none;
  border: 1px solid #da6702;
  font-size: 17px;
  font-weight: 400;
  font-family: Arial, sans-serif;
  border-radius: 0;
}

.singleProduct__content-right .button {
  text-align: center;
  margin: 15px 0 0;
}

.singleProduct__content-right .button button {
  font-size: 15px;
  font-family: Arial, sans-serif;
  color: #fff;
  background: linear-gradient(to bottom, #ef3437 0%, #ac0606 100%);
  border: 1px solid #840607;
  text-decoration: none;
  padding: 7px 11px 8px;
  outline: none;
}

.singleProduct__content-right .button button:first-child {
  margin-bottom: 10px;
}

.productManufacturer__tags {
  margin-top: 52px;
}

.brands_list_title {
  margin: 5px;
  font-weight: bold;
}

ul.tags {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  max-height: 200px;
  overflow-y: scroll;
}

ul.tags li {
  list-style-type: none;
  border-bottom: 1px solid transparent;
  margin: 4px;
}

ul.tags li:hover {
  transition: .15s;
  border-bottom: 1px solid;
}

ul.tags li a {
  font-size: 12px;
  color: #696868;
}

.productList {
  margin-bottom: 50px;
}

.productList.row {
  display: flex;
  flex-wrap: wrap;
}

.buyClick {
  border-radius: 2px;
  max-width: 350px !important;
}

.buyClick__top {
  overflow: hidden;
  background: #B15050;
  color: #fff;
  text-shadow: 0 -1px 0 #1B457D;
  font-size: 13px;
  position: relative;
  padding: 12px;
}

.buyClick__top-title {
  max-width: 280px;
}

.buyClick__top-close {
  position: absolute;
  top: 4px;
  right: 8px;
  cursor: pointer;
}

.buyClick__title {
  padding: 12px;
  color: #333;
}

.buyClick__form .field {
  padding: 0 12px 12px;
}

.buyClick__form .field label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.buyClick__form .field input {
  outline: none;
  box-shadow: none !important;
}

.buyClick__form .field input.error {
  border: 1px solid red;
}

.buyClick__form .button {
  border-top: 1px solid #e6e6e6;
  background: #f2f2f2;
  padding: 10px 0 0 0;
  position: relative;
  text-align: center;
}

.buyClick__form .button .result {
  border-top: 1px solid #070;
  color: #070;
  background: #c8ed9c;
  padding: 8px 10px;
}

.buyClick__form .button button {
  padding: 10px;
  background: #b15555;
  color: white;
  font-size: 14px;
  margin-bottom: 10px;
}

.sendQuestion {
  max-width: 332px !important;
}

.sendQuestion__success {
  display: none;
  margin: 0;
  padding: 70px 0;
  font: normal 18px Calibri, arial, sans-serif;
  line-height: 22px;
  text-align: center;
  color: #cd152c;
}

.sendQuestion__wrap {
  padding: 15px;
}

.sendQuestion__wrap-title {
  font-size: 26px;
  font-weight: 400;
  color: #cd152c;
  margin: 0 0 10px;
  font-family: Arial, sans-serif;
  text-align: center;
}

.sendQuestion__wrap-product {
  color: #444;
  font-size: 14px;
  margin-bottom: 10px;
}

.sendQuestion__wrap-form .field {
  padding: 0;
  max-width: 236px;
  margin: 5px auto;
}

.sendQuestion__wrap-form .field input {
  box-shadow: none;
}

.sendQuestion__wrap-form .field input.error {
  border: 1px solid red;
}

.sendQuestion__wrap-form .field textarea {
  box-shadow: none;
  width: 100%;
  min-height: 100px;
}

.sendQuestion__wrap-form button {
  color: #fff;
  outline: none;
  display: block;
  margin: 15px auto 10px auto;
  padding: 6px 50px;
  border: 1px solid #da6702;
  font-size: 17px;
  font-weight: 400;
  font-family: Arial, sans-serif;
  background: linear-gradient(to bottom, #fd9833 0%, #ec3c02 100%);
}

.relatedProducts .h2 {
  border-bottom: none;
}

.relatedProducts .row {
  display: flex;
  flex-wrap: wrap;
}

.topProducts {
  padding: 30px;
}

.topProducts ul {
  padding: 0;
  margin: 0;
  columns: 4;
}

@media (max-width: 576px) {
  .topProducts ul {
    columns: 2;
  }
}

.topProducts ul li {
  list-style-type: none;
}

.topProducts ul li a {
  color: black;
  font-size: 14px;
}

.topProducts ul li a:hover {
  text-decoration: underline;
  opacity: .8;
}

.product_tagsList {
  margin-bottom: 30px;
}

.product_tagName {
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;
}

.product_tags {
  display: inline-block;
}

