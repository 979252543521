#column-left {
  .mebel_menu, .catalog__list {
    margin: 0 auto;
    width: 222px;
    background: #f7f6f6;
    box-shadow: 0px 2px 9px 0px rgba(79, 78, 78, 0.75);
    padding: 7px 10px 7px 20px;

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style-type: none;
        padding: 0 0 0 12px;
        margin: 5px 0 0;
        position: relative;

        a {
          font-size: 14px;
          font-family: Arial, sans-serif;
          color: #696868;
          text-decoration: none;

          &:before {
            display: block;
            content: '';
            width: 7px;
            height: 7px;
            box-sizing: border-box;
            border: 1px solid #696868;
            border-radius: 50%;
            position: absolute;
            top: 6px;
            left: 0px;
          }
        }
      }
    }
  }
}

.sidebar__title {
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #d7100e;
  text-transform: uppercase;
  margin: 23px 0 14px;
  text-align: center;
}

.catalog__list-item {
  &.active, &:hover {
    text-decoration: underline!important;
  }
}

.mebel_menu-li {
  &.active, &:hover {
    text-decoration: underline!important;
  }
}

.category__description {
  margin-bottom: 40px;
  &.active {
    .col-sm-10 {
      height: 100%;
      &:after {
        display: none;
      }
    }
  }
  .col-sm-10 {
    height: 200px;
    overflow: hidden;
    position: relative;

    &:after {
      content: '';
      height: 50px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
      transition: height .25s ease;
    }
  }

  span.show-more {
    margin-top: 20px;
    margin-bottom: 40px;
    cursor: pointer;
    display: inline-block;
  }
}