.latestProduct {
  &__list {
    .row {
      width: 100%;
      margin: 0;
    }
  }
}

.productsWrap {
  padding: 9px 0;
  margin: 0;
}

.pageHome__wrap {
  .content {
    padding: 0;
  }
}

.productsTitle {
  color: #ac0607;
  font-size: 25px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 30px;
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}

.blockTitle {
  color: #ac0607;
  font-size: 25px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 30px;
  margin: 6px 0;
}
